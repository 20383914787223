<template>
    <v-alert type="error" dismissible>
        {{ message }}
    </v-alert>
</template>

<script>
export default {
    name: 'Error',
    props: {
        errorId: String,
        message: String,
    },
    data: () => ({
        timeout: null,
        close: true,
    }),
    mounted() {
        setTimeout(() => {
            this.$emit('closeError', this.errorId)
        }, 10000)
    },
}
</script>

<style></style>
